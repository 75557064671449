<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h3>Bestellingen</h3>

        <DataTable :value="orders" v-model:expandedRows="expandedRows" editMode="cell" dataKey="id">
          <Column :expander="true" headerStyle="width: 3rem" />

          <Column field="createdAt" header="Datum" :sortable="true"></Column>
          <Column field="user.username" header="Gebruiker" :sortable="true"></Column>
          <Column field="total" header="Totaal" :sortable="true">
            <template #body="slotProps">
              {{formatCurrency(slotProps.data.total)}}
            </template>
          </Column>
          <Column field="totalIncTax" header="Totaal incl. BTW" :sortable="true">
            <template #body="slotProps">
              {{formatCurrency(slotProps.data.totalIncTax)}}
            </template>
          </Column>
          <Column field="completed" header="Status" :sortable="true">
            <template #body="{data}">
              <span style="cursor:pointer">
                <span :class="`customer-badge status-${data.completed ? 'completed' : 'in-progress'}`">{{data.completed ? 'Ontvangen' : 'In behandeling'}}</span>
                <i class="p-ml-2 pi pi-pencil"></i>
              </span>
            </template>
            <template #editor="slotProps">
              <Dropdown v-model="slotProps.data['completed']" :options="statuses" @update:modelValue="onStatusUpdate($event, slotProps)"  optionLabel="label" optionValue="value">
                <template #option="slotProps">
                  <span :class="'product-badge status-' + slotProps.option.class">{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div>
              <h5>Producten</h5>
              <DataTable :value="slotProps.data.items" responsiveLayout="scroll" paginator :rows="20">
                <Column field="sku" header="SKU" :sortable="true">
                  <template #body="{data}">
                    <span class="p-column-title">SKU</span>
                    {{data.sku}}
                  </template>
                </Column>
                <Column field="name" header="Product" :sortable="true">
                  <template #body="{data}">
                    <span class="p-column-title">Product</span>
                    {{data.name}}
                  </template>
                </Column>
                <Column field="price" header="Prijs p/s" :sortable="true">
                  <template #body="slotProps">
                    <span class="p-column-title">Prijs p/s</span>
                    {{formatCurrency(slotProps.data.price)}}
                  </template>
                </Column>
                <Column field="taxPercentage" header="BTW" :sortable="true">
                  <template #body="{data}">
                    <span class="p-column-title">BTW</span>
                    {{data.taxPercentage}} %
                  </template>
                </Column>
                <Column field="quantity" header="Aantal" :sortable="true">
                  <template #body="{data}">
                    <span class="p-column-title">Aantal</span>
                    {{data.quantity}}
                  </template>
                </Column>
                <Column field="total" header="Totaal" :sortable="true">
                  <template #body="{data}">
                    <span class="p-column-title">Totaal</span>
                    {{formatCurrency(data.total)}}
                  </template>
                </Column>
                <template #footer>
                  <div class="p-grid p-jc-end">
                    <div class="p-col-10 p-md-4">
                      <table class="total-list">
                        <tr>
                          <th>Totaal</th>
                          <td>&euro;</td>
                          <td>{{formatCurrency(slotProps.data.total, false)}}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <th>BTW:</th>
                        </tr>
                        <tr v-for="(totalTaxPrice, taxPercentage) in slotProps.data.taxes" v-bind:key="taxPercentage">
                          <th>{{ taxPercentage }} %</th>
                          <td>&euro;</td>
                          <td>{{formatCurrency(totalTaxPrice, false)}}</td>
                        </tr>
                        <tr><td>&nbsp;</td></tr>
                        <tr>
                          <th>Totaal incl. BTW</th>
                          <td>&euro;</td>
                          <td>{{formatCurrency(slotProps.data.totalIncTax, false)}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </template>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Translate from '../../mixins/Translate.js'

export default {
  mixins: [Translate],
  data() {
    return {
      orders: null,
      products: null,
      expandedRows: [],
      statuses: [
        {label: 'In behandeling', value: false, class: 'completed'},
        {label: 'Ontvangen', value: true, class: 'in-progress'}
      ]
    }
  },
  mounted() {
    this.$root.orderService.getAdminOrders().then(data => this.orders = data);
  },
  methods: {
    onStatusUpdate(newValue, props) {
      this.$root.orderService.updateStatus({
        id: props.data.id,
        completed: newValue
      }).then(() => {
        this.$root.updateAdminMenu()
      }).catch(() => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Er ging wat mis'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    margin-right: 10px;
  }
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-completed {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-in-progress {
    background: #FFD8B2;
    color: #805B36;
  }
}

.total-list {
  width: auto;

  th {
    padding-right: 20px;
    text-align: left;
  }

  td {
    text-align: right;
  }
}
</style>
